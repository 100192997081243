.header_user_info {
  float: right;
  position: relative; }

.header_user_info a {
  color: #00739f;
  font-weight: bold;
  display: block;
  padding: 8px 0 11px 8px;
  cursor: pointer;
  line-height: 18px; }

.header_user_info a:hover, .header_user_info a.active {
  text-decoration: underline; }

.logged_icon {
  width: 22px;
  height: 22px;
  display: block;
  background: url(/img/account_icons.png) 0 0 no-repeat; }

.logged_icon:hover {
  background-position: right 0; }

.not_logged_icon {
  width: 23px;
  height: 22px;
  display: block;
  background: url(/img/account_icons.png) 0 bottom no-repeat;
  margin-right: 2px; }

.not_logged_icon:hover {
  background-position: -27px bottom; }

.header_user_info .auth {
  position: absolute;
  right: -12px;
  top: 0;
  z-index: 4000;
  width: 273px;
  background: #ffffff;
  display: none;
  border-left: 2px solid rgba(19, 49, 0, 0.2);
  border-right: 2px solid rgba(19, 49, 0, 0.2);
  border-bottom: 4px solid rgba(19, 49, 0, 0.2);
  background-clip: content-box; }

.header_user_info .auth.active {
  display: block; }

.auth .tabs-nav {
  margin-bottom: 15px;
  padding-top: 4px; }

.auth .tabs-nav li {
  display: inline-block;
  margin-right: 15px;
  font-family: "myriadpro_bold"; }

.auth .tabs-nav li a {
  /*display: inline;*/
  text-transform: uppercase;
  font-size: 12px;
  padding: 0;
  border-bottom: 1px solid rgba(0, 115, 159, 0.3);
  text-decoration: none; }

.auth .tabs-nav li a:hover {
  border-bottom: 1px solid rgba(213, 21, 33, 0.3);
  text-decoration: none;
  color: #d51521; }

.auth .tabs-nav li.active a {
  pointer-events: none;
  color: #333;
  text-decoration: none;
  border-bottom: 0; }

.auth .inner {
  padding: 6px 22px 20px; }

.auth input {
  width: 100%;
  height: 36px;
  line-height: 36px;
  border-radius: 5px;
  background: #f4f4f4;
  color: #333;
  font-size: 15px;
  font-family: "myriadpro_bold";
  padding: 0 8px;
  box-shadow: inset 0 3px 0 #e0d8d2;
  border: 0; }

.auth .form-row {
  margin-bottom: 11px; }

.auth button {
  color: #fff;
  font: normal 18px/32px "appetiteregular";
  cursor: pointer;
  border-radius: 5px;
  background: #51bec0;
  box-shadow: 0 4px 0 #3fa6a8;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 99px;
  float: left;
  border: 0;
  margin-right: 11px; }

.auth button:hover {
  background: #3cbec0;
  box-shadow: 0 4px 0 #2da6a8; }

.show-forget {
  font-size: 13px; }

.show-forget:hover {
  color: #d51521;
  text-decoration: none !important; }

.forget-form button {
  width: 100%;
  float: none;
  margin-top: 11px; }

.auth [id^="tab-"] {
  display: none; }

.auth .active[id^="tab-"] {
  display: block; }

.auth .forget-form {
  display: none;
  padding-bottom: 20px; }

.auth .forget-form.active {
  display: block; }

.auth .tabs .content {
  display: none; }

.auth .tabs .content.active {
  display: block; }

.auth .close {
  width: 21px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 9px;
  background: url(/img/close-fancy-bg.png) 0 0 no-repeat; }

.auth .close:hover {
  background-position: 0 bottom; }

.usernav-links {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 999;
  visibility: hidden;
  margin-top: -10px;
  border-right: 1px solid rgba(19, 49, 0, 0.2);
  border-left: 1px solid rgba(19, 49, 0, 0.2);
  border-bottom: 2px solid rgba(19, 49, 0, 0.2);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px; }

.usernav-links.show {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
  margin-top: 0; }

.usernav-links li {
  padding-bottom: 10px; }

.usernav-links a {
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 115, 149, 0.3);
  display: inline;
  font-size: 14px;
  padding-bottom: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  padding: 0; }

.usernav-links a:hover {
  text-decoration: none;
  color: #d51521;
  border-color: #ecb2b1; }

.header_user_info .account.show {
  position: relative;
  z-index: 1000; }

/*# sourceMappingURL=blockuserinfo.css.map */
